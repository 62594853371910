import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpAlertAnimations } from 'src/app/models/HttpAlertAnimations';
import { UserAuthService } from 'src/app/services/user-auth.service';

@Component({
  selector: 'app-forgotpass',
  templateUrl: './forgotpass.component.html',
  styleUrls: ['./forgotpass.component.scss'],
  animations: [HttpAlertAnimations]
})
export class ForgotpassComponent implements OnInit {
  requestFail: string = null;
  requestSuccess: string = null;

  constructor(public UserAuth: UserAuthService, public router: Router) { }

  ngOnInit(): void {
  }

  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  requestReset() {
    this.UserAuth.password_RequestReset(this.resetForm).subscribe(
      result => {
        this.requestSuccess = result.displayText;
        setTimeout(() => {
          this.router.navigateByUrl('Login');
        }, 3000);
      },
      error => {
        this.requestFail = error.error.displayText;
        setTimeout(() => {
          this.requestFail = null;
        }, 5000);
      }
    );
  }

}
