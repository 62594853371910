import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { UserAuthService } from './user-auth.service';

const INTERVAL = 60000 //ms (5 sec)
const LAST_ACTIVE = 'LastActive';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {
  constructor(private UserAuth: UserAuthService, private router: Router, private ngZone: NgZone) {
    dayjs.extend(utc);
    this.compareSession();
    this.startInterval();
  }

  get LastActive() {
    return dayjs(localStorage.getItem(LAST_ACTIVE)).toDate();
  }
  set LastActive(date: Date) {
    localStorage.setItem(LAST_ACTIVE, date.toString());
  }

  startInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.compareSession();
      }, INTERVAL);
    });
  }

  compareSession() {
    this.ngZone.run(() => {
      if(this.UserAuth.userProfileSubject.value && !this.UserAuth.isLoggedIn()) {
        this.UserAuth.user_Logout();
      }
    });
  }

}
