import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpAlertAnimations } from 'src/app/models/HttpAlertAnimations';
import { UserAuthService } from 'src/app/services/user-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [HttpAlertAnimations]
})
export class LoginComponent implements OnInit {
  loginSuccess = null;
  loginFail = null;

  constructor(private UserAuth: UserAuthService, public router: Router) { }

  ngOnInit(): void {
    
  }

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  login() {
    this.UserAuth.user_Login(this.loginForm).subscribe(
      result => {
        this.loginSuccess = result.displayText;
        setTimeout(() => {
          this.router.navigateByUrl("");
        }, 3000);
      },
      error => {
        this.loginFail = error.error.displayText;
        this.loginForm.reset();
        setTimeout(() => {
          this.loginFail = null;
        }, 5000);
      }
    )
  }
}
