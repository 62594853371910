import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-projects',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  templateToUse: string = null;
  activeSlide: number = 0;

  constructor(public dialogRef: MatDialogRef<ProjectComponent>, 
    @Inject(MAT_DIALOG_DATA) public modalData: any) {
      this.templateToUse = modalData.template;
    }

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementsByClassName("slide-off").item(0).className = "slide-on";
    }, 3000);
  }

  cycleUp() {
    this.updateSlideshow(this.activeSlide+1);
  }

  cycleDown() {
    this.updateSlideshow(this.activeSlide-1);
  }

  updateSlideshow(moveToIndex: number) {
    // if(document.getElementsByClassName("slide-on").length === 0) { 
    //   document.getElementsByClassName("slide-off").item(0).className = "slide-on";
    // } else {  }
    document.getElementsByClassName("slide-on").item(0).className = "slide-off";
    var slides = document.getElementsByClassName("slide-off");
    if(moveToIndex > slides.length-1) {
      //wrap around to 0
      slides.item(0).className = "slide-on"
      this.activeSlide = 0;
    } else if(moveToIndex < 0) {
      //wrap to end
      slides.item(slides.length-1).className = "slide-on";
      this.activeSlide = slides.length-1;
    } else {
      slides.item(moveToIndex).className = "slide-on"
      this.activeSlide = moveToIndex;
    }
  }

}
