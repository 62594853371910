import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { UserAuthService } from '../services/user-auth.service';
import { convertDate } from '../global-scripts/convert-date';
import { ForumService } from '../services/forum.service';
import { AutoLogoutService } from '../services/auto-logout.service';
import { HttpAlertAnimations } from '../models/HttpAlertAnimations';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  animations: [HttpAlertAnimations]
})
export class MyProfileComponent implements OnInit {
  postsMade = null;
  postsLiked = null;
  postsDisliked = null;
  repliesReceived = null;
  profileUpdateStatus = null;
  profileUpdateClass = "http-Success";

  constructor(public UserAuth: UserAuthService, 
    public ForumSvc: ForumService, 
    public router: Router, private Auto: AutoLogoutService) { }

  profileDetail = [
    {name: "firstName", displayName: "First Name", type: "text", editable: true},
    {name: "middleName", displayName: "Middle Name/Initial", type: "text", editable: true},
    {name: "lastName", displayName: "Last Name", type: "text", editable: true},
    {name: "email", displayName: "Email Address", type: "email", editable: false},
    {name: "userName", displayName: "Display Name / Username", type: "text", editable: true}
  ];

  profileForm = new FormGroup({
    firstName: new FormControl({value: this.UserAuth.userProfile("firstName"), disabled: true}, [Validators.required]),
    lastName: new FormControl({value: this.UserAuth.userProfile("lastName"), disabled: true}, [Validators.required]),
    email: new FormControl({value: this.UserAuth.userProfile('email'), disabled: true}, [Validators.required, Validators.email]),
    //personal details only accessed/entered via the profile component
    middleName: new FormControl({value: this.UserAuth.userProfile("middleName"), disabled: true}, []),
    userName: new FormControl({value: this.UserAuth.userProfile('userName'), disabled: true}, [Validators.required, Validators.maxLength(30)]),
  }, {}
  );

  ngOnInit(): void {
    this.forum_Activity("Made");
    this.forum_Activity("Like");
    this.forum_Activity("Dislike");
    this.forum_Activity("Replies");
  }

  convert_Date(date: Date) {
    return convertDate(date, "post");
  }
  
  switch_State(fieldName: string) {
    if(this.profileForm.controls[fieldName].disabled) {
      this.profileForm.controls[fieldName].enable();
    } else { this.profileForm.controls[fieldName].disable(); }
  }

  check_Disabled(fieldName: string) : boolean {
    if(this.profileForm.controls[fieldName].disabled) {
      return true;
    } else { return false; }
  }

  patchForm(user: any) {
    Object.keys(user).forEach(field => {
      if(this.profileForm.get(field)) {
        this.profileForm.get(field).patchValue(user[field]);
        if(this.profileForm.contains(field)) { this.switch_State(field); }
      }
    });
  }

  updatePersonalDetails() {
    this.UserAuth.myProfileUpdate(this.profileForm).subscribe(
      result => {
        this.profileUpdateStatus = result.displayText;
        this.profileUpdateClass = "http-Success";
        this.UserAuth.userProfile_Update(result.user);
        this.patchForm(result.user);
        setTimeout(() => {
          this.profileUpdateStatus = null;
        }, 3000);
      },
      error => {
        this.profileUpdateStatus = error.error.displayText;
        this.profileUpdateClass = "http-Fail";
        setTimeout(() => {
          this.profileUpdateStatus = null;
        }, 5000);
      }
    );
  }

  forum_Activity(section: string) {
    switch (section) {
      case "Made":
        this.ForumSvc.forumActivity(section).subscribe(
          result => { this.postsMade = result.posts; }
        );
        break;
      case "Like":
        this.ForumSvc.forumActivity(section).subscribe(
          result => { this.postsLiked = result.posts; }
        );
        break;
      case "Dislike":
        this.ForumSvc.forumActivity(section).subscribe(
          result => { this.postsDisliked = result.posts; }
        );
        break;
      case "Replies":
        this.ForumSvc.forumActivity(section).subscribe(
          result => { this.repliesReceived = result.posts; }
        );
        break;
    }
  }

}
