import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanLoad, CanActivate } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ForgotpassComponent } from './auth/forgotpass/forgotpass.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ResetpassComponent } from './auth/resetpass/resetpass.component';
import { ContactComponent } from './contact/contact.component';
import { ForumComponent } from './forum/forum.component';
import { passresetMatcher } from './global-scripts/passreset-matcher';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ProjectsComponent } from './projects/projects.component';
import { UserAdminComponent } from './user-admin/user-admin.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { RoleGuardService as RoleGuard } from './services/role-guard.service';

const routes: Routes = [
  {path: "", component: WelcomeComponent},
  {path: "OurStory", component: AboutComponent},
  {path: "OurProjects", component: ProjectsComponent},
  {path: "ContactUs", component: ContactComponent},
  {path: "Register", component: RegisterComponent},
  {path: "Login", component: LoginComponent},
  {path: "ForgotPassword", component: ForgotpassComponent},
  {matcher: passresetMatcher, component: ResetpassComponent},
  {path: "Forum", component: ForumComponent},
  {path: "UserAdmin", component: UserAdminComponent, canActivate: [RoleGuard], data: {expectedRole: 'Admin'}},
  {path: "MyAccount", component: MyProfileComponent, canActivate: [AuthGuard]},
  {path: "**", redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
