<mat-dialog-content>
    <ng-container *ngIf="modalData.template === 'dragontamers'">
        <div fxLayout="row wrap" fxLayoutAlign="space-between">
            <div class="title" fxFlex="0 0 100%">
                <h1>Dragontamers</h1>
            </div>
            <div fxFlex="0 0 48%" class="detail-backing">
                <ul>
                    <li><b>Categories:</b> Card Games, Video Games</li>
                    <li><b>Release Date (Card):</b> TBD</li>
                    <li><b>Release Date (Digital):</b> TBD</li>
                    <li><b>Multiplayer:</b> Yes</li>
                    <li><b>Content Rating:</b> 13+</li>
                </ul>
            </div>
            <div fxFlex="0 0 48%">
                <div class="slide-container">
                    <img class="slide-off" src="../assets/title_splash.png">
                    <img class="slide-off" src="../assets/card_examples.png">
                    <div class="prev" (click)="cycleDown()">&#10094;</div>
                    <div class="next" (click)="cycleUp()">&#10095;</div>
                </div>
            </div>
            <div fxFlex="0 0 48%" class="detail-backing">
                <h2>Overview</h2>
                <p><i>Dragontamers</i> is a strategy card game similar to popular games such as <i>Magic the Gathering</i>, 
                    <i>Yu-Ghi-Oh</i>, etc. Gameplay focuses around the accruement of primal energies to cast spells and 
                    summon creatures to do battle. The game only ends when one of the players’ life points reaches 0. 
                    This is accomplished through various means: creature attack, spells, active abilities, etc. 
                    The purpose of this game is to attempt to combine some of the popular strategies seen in the 
                    aforementioned popular card games in a more balanced manner, allowing for a “long-game” experience 
                    whilst still being fun and a varied gaming experience every game, even with the same decks pairing 
                    off to do battle.
                </p>
                <h4><b>The Primal Energies:</b></h4>
                <p>All of these strategy card games revolve around an economy system to use the various spells, 
                    creatures, and abilities at the player’s disposal. Energy is treated most similarly in this game 
                    as to that of the land of Magic the Gathering in that each card tied to one of the primal energies 
                    will cost a certain amount of that energy, thus helping do balance multicolored deck strategies as 
                    well as making it easier to connect the various strategies of each color.</p>
            </div>
            <div fxFlex="0 0 48%" class="detail-backing">
                <h2>Download</h2>
                <div>Dragontamers Rules PDF:<br><button mat-stroked-button>Download</button></div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="modalData.template === 'free-maps'">
        <div fxLayout="row wrap" fxLayoutAlign="space-between">
            <div class="title" fxFlex="0 0 100%">
                <h1>Free Tabletop RPG Map Collection</h1>
            </div>
            <div fxFlex="0 0 48%" class="detail-backing">
                <ul>
                    <li><b>Categories:</b> Tabletop RPGs</li>
                    <li><b>Release Date:</b> Ongoing</li>
                    <li><b>Content Rating:</b> 13+</li>
                </ul>
            </div>
            <div fxFlex="0 0 48%">
                <!-- <div class="slide-container">
                    <img class="slide-off" src="../assets/title_splash.png">
                    <img class="slide-off" src="../assets/card_examples.png">
                    <div class="prev" (click)="cycleDown()">&#10094;</div>
                    <div class="next" (click)="cycleUp()">&#10095;</div>
                </div> -->
            </div>
            <div fxFlex="0 0 48%" class="detail-backing">
                <h2>Overview</h2>
                <p>Here at Geekwerks, it’s our pleasure to make free maps for use in tabletop games. 
                    We want to thank you for visiting our site. Check out other games while you’re here. 
                    Come back regularly for more content!  We simply ask that you credit us for any usage.  
                </p>
            </div>
            <div fxFlex="0 0 48%" class="detail-backing">
                <h2>Download</h2>
                <div>
                    <p><b>Map Pack 1</b></p>
                    <ul style="list-style: inside;">
                        <li><b>Clifton</b> - A small tropical clifside camp with a river ending in a waterfall.</li>
                        <li><b>Oracle</b> - A mountain stronghold ringed by mountains, then a lake, then more mountains.</li>
                        <li><b>Makeshift Port 1</b> - A small port entry with a reef shallows slighty offshore.</li>
                        <li><b>Underground Dungeon 1</b> - A tight, multilayered underground cave system.</li>
                    </ul>
                    <br>
                    <a mat-stroked-button target="_blank" href=
                    "https://drive.google.com/file/d/1JXOtZtvKxPeK3ThpGHpXl40JSzrxZ6_c/view?usp=sharing">DOWNLOAD</a></div>
                    <br><br>
                    <div>
                        <p><b>Map Pack 2</b></p>
                        <ul style="list-style: inside;">
                            <li><b>Pirate Cove</b> - A shanty hidden on an island my hold more than it seems at first glance.</li>
                            <li><b>Castle Siege</b> - Major river crossings have always been key strategic points. This one can be defended from both sides.</li>
                            <li><b>Throne Room</b> - Massive swords are an imposing sight to behold when addressing the royalty.</li>
                        </ul>
                        <br>
                        <a mat-stroked-button target="_blank" href=
                        "">DOWNLOAD</a></div>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding: 0;">
    <button mat-stroked-button mat-dialog-close>CLOSE</button>
</mat-dialog-actions>