import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { userIndex } from '../global-scripts/userindex';
import { HttpAlertAnimations } from '../models/HttpAlertAnimations';
import { User } from '../models/user';
import { AutoLogoutService } from '../services/auto-logout.service';
import { UserAuthService } from '../services/user-auth.service';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss'],
  animations: [HttpAlertAnimations]
})
export class UserAdminComponent implements OnInit {

  searchFailTextLeader = "No results match";
  updateSucceeded = null;
  updateFailed = null;

  constructor(public UserAuth: UserAuthService, public router: Router, private Auto: AutoLogoutService) {
    this.registeredUsersSource.data = null;
  }

  tableDisplayCols: string[] = ["date_registered","firstName","lastName","userName","email","roleNames","userStatus","actions"];
  selectedUserFields: Object[] = [
    {name: "date_registered", displayName: "Date Registered"},
    {name: "firstName", displayName: "First Name"},
    {name: "lastName", displayName: "Last Name"},
    {name: "userName", displayName: "Username/Display Name"},
    {name: "email", displayName: "Email Address"},
    {name: "roleNames", displayName: "Roles"},
    {name: "userStatus", displayName: "User Status"}
  ];

  registeredUsersSource = new MatTableDataSource();
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selectedUser: User = null;
  assignableRoles = ["Public User","Playtester","Admin","Moderator"];
  statuses = ["Active", "Locked"];

  selectedUserForm = new FormGroup({
    _id: new FormControl(''),
    roleNames: new FormControl(''),
    userStatus: new FormControl('')
  });

  ngOnInit(): void {
    this.getAllAccounts();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.registeredUsersSource.filter = filterValue.trim().toLowerCase();
  }

  getAllAccounts() {
    this.UserAuth.admin_GetAllAccounts().subscribe(
      result => {
        this.registeredUsersSource.data = result as User[];
      },
      error => {
        this.searchFailTextLeader = error.error.displayText;
      }
    );
  }

  selectUser(rowProfile: User) {
    this.selectedUser = rowProfile;
    this.selectedUserForm.patchValue({_id: this.selectedUserInfo("_id")});
    this.selectedUserForm.patchValue({roleNames: this.selectedUserInfo("roleNames")});
    this.selectedUserForm.patchValue({userStatus: this.selectedUserInfo("userStatus")});
  }

  selectedUserInfo(output) {
    switch (output) {
      case "fullname": return this.selectedUser.firstName + " " + this.selectedUser.lastName;
      case "displayName":
        if(this.selectedUser.userName_Index) 
        { return this.selectedUser.userName + userIndex(this.selectedUser.userName_Index); }
        else { return this.selectedUser.userName; }
      default: return this.selectedUser[output];
    }
  }

  updateSelected() {
    this.UserAuth.admin_UpdateUser(this.selectedUserForm).subscribe(
      result => {
        this.updateSucceeded = result.displayText;
        this.selectedUser = result.user;
        setTimeout(() => {
          this.getAllAccounts();
          this.updateSucceeded = null;
        }, 3000);
      },
      error => {
        this.updateFailed = error.error.displayText;
        setTimeout(() => {
          this.updateFailed = null;
        }, 5000);
      }
    );
  }

  deleteUser() {
    this.UserAuth.admin_DeleteAccount(this.selectedUser._id).subscribe(
      result => {
        this.updateSucceeded = result.displayText;
        this.selectedUser = null;
        this.getAllAccounts();
        setTimeout(() => {
          this.updateSucceeded = null;
        }, 3000);
      },
      error => {
        this.updateFailed = error.error.displayText;
        setTimeout(() => {
          this.updateFailed = null;
        }, 5000);
      }
    );
  }

}
