<div class="main-container">
    <mat-card>
        <mat-card-title>Our Story</mat-card-title>
        <mat-card-content>
            <p>Geekwerks started as a passion project between the founding members, friends who met through
                gaming together, to create together as well as to sharpen their professional and creative skills. The 
                company was formed in October of 2020 in Annapolis, Maryland. You can read more about the founding members 
                below.
            </p>
        </mat-card-content>
    </mat-card>
    <span class="staff-horzspacer">&nbsp;</span>
    <div class="staff-box">
        <mat-card class="user-card">
            <mat-card-title>Joshua Cleveland</mat-card-title>
            <mat-card-subtitle>Principal Agent</mat-card-subtitle>
            <img mat-card-image src="../assets/josh_profile.jpg" alt="Joshua Cleveland">
          </mat-card>
          <span class="staff-vertspacer">&nbsp;</span>
          <mat-card class="quote-card">
            <mat-card-content>
              <h1><i>"A lifetime board-and-video gamer, I have spent the majority of my free time throughout 
                my youth and adult life immersed in the world of hobby gaming."</i></h1>
            </mat-card-content>
            <mat-card-actions>
              <button mat-stroked-button (click)="openProfile('josh')">LEARN MORE</button>
            </mat-card-actions>
          </mat-card>
    </div>
    <span class="staff-horizspacer">&nbsp;</span>
    <div class="staff-box">
          <mat-card class="quote-card">
            <mat-card-content>
              <h1><i>"I hold bachelors’ degrees in Biochemistry and Computer Engineering from Virginia Tech. 
                I am taking more school to learn about machine learning and AI systems because I like school 
                and I kinda hate myself…"</i></h1>
            </mat-card-content>
            <mat-card-actions>
              <button mat-stroked-button (click)="openProfile('nic')">LEARN MORE</button>
            </mat-card-actions>
          </mat-card>
          <span class="staff-vertspacer">&nbsp;</span>
          <mat-card class="user-card">
            <mat-card-title>Nic Rohr</mat-card-title>
            <mat-card-subtitle>Chief Technical Officer</mat-card-subtitle>
            <img mat-card-image src="../assets/nic_profile.png" alt="Joshua Cleveland">
          </mat-card>
    </div>
    <span class="staff-horizspacer">&nbsp;</span>
    <div class="staff-box">
        <mat-card class="user-card">
            <mat-card-title>Joseph Grindstaff</mat-card-title>
            <mat-card-subtitle>Principal Engineer</mat-card-subtitle>
            <img mat-card-image src="../assets/joe_profile.jpg" alt="Joshua Cleveland">
          </mat-card>
          <span class="staff-vertspacer">&nbsp;</span>
          <mat-card class="quote-card">
            <mat-card-content>
              <h1><i>"Once I had usable internet in college and was exposed to the wider world of gaming, fantasy novels, 3D modeling, programming,
                web design, etc I veered into these areas for a career in development."</i></h1>
            </mat-card-content>
            <mat-card-actions>
              <button mat-stroked-button (click)="openProfile('joe')">LEARN MORE</button>
            </mat-card-actions>
          </mat-card>
    </div>
</div>