<mat-card>
    <mat-card-title>Password Reset</mat-card-title>
    <mat-card-content>
        <form [formGroup]="resetForm">
            <mat-form-field>
                <mat-label>New Password</mat-label>
                <input type="password" formControlName="password" matInput required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Confirm Password</mat-label>
                <input type="password" formControlName="passwordConfirm" matInput required>
            </mat-form-field>
        </form>
        <div *ngIf="successText" [@inOutAnimation] class="http-Success">{{successText}}</div>
        <div *ngIf="failText" [@inOutAnimation] class="http-Fail">{{failText}}</div>
    </mat-card-content>
    <mat-card-actions>
        <div>
            <button mat-stroked-button [disabled]="!resetForm.valid" (click)="resetPassword()">RESET PASSWORD</button>
        </div>
    </mat-card-actions>
</mat-card>