import { trigger, style, animate, transition, animation } from '@angular/animations'

export const HttpAlertAnimations =
  trigger(
    'inOutAnimation', 
    [
      transition(
        ':enter', 
        [
          style({ opacity: 0 }),
          animate('1s ease-out', 
                  style({ opacity: 1 }))
        ]
      ),
      transition(
        ':leave', 
        [
          style({ opacity: 1 }),
          animate('1s ease-in', 
                  style({ opacity: 0 }))
        ]
      )
    ]
  )