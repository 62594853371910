import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from './user-auth.service';

@Injectable({
  providedIn: 'root'
})
export class ForumService {

  APIURL = environment.postURL

  constructor(public http: HttpClient, public router: Router, public UserAuth: UserAuthService) {}

  get_PostsBySelection(cat: string, thr: string) {
    return this.http.post<any>(this.APIURL + "/API/GetSelectedPosts", {category: cat, thread: thr});
  }

  add_Post(cat: string, thr: string, modalData: any) {
    return this.http.post<any>(this.APIURL + "/API/AddThreadPost", {category: cat, thread: thr, postData: modalData, userID: this.UserAuth.userProfile("_id"), displayName: this.UserAuth.userProfile("displayName")});
  }

  Like_Dislike(direction: string, post: any) {
    return this.http.post<any>(this.APIURL + "/API/PostLikeDislike", {thumb: direction, postData: post, userID: this.UserAuth.userProfile("_id")});
  }

  forumActivity(section: string) {
    return this.http.post<any>(this.APIURL + "/API/UserForumActivity", {section: section, userID: this.UserAuth.userProfile("_id")});
  }


  //management only
  deleteAll() {
    return this.http.delete<any>(this.APIURL + "/API/DeletePosts");
  }

}
