<div class="main-container">
    <div class="toplists">
        <mat-card>
            <mat-card-subtitle>Project Directory</mat-card-subtitle>
            <mat-card-content>
                <button mat-flat-button [matMenuTriggerFor]="BoardGames" disabled>Board Games</button>
                <mat-menu #BoardGames="matMenu">
                    <button mat-menu-item (click)="openProject('ship-to-ship')">Guns & Glory: Seas of Fame</button>
                    <button mat-menu-item (click)="openProject('shadow-governance')">Shadow Governance</button>
                </mat-menu>

                <button mat-flat-button [matMenuTriggerFor]="CardGames" disabled>Card Games</button>
                <mat-menu #CardGames="matMenu">
                    <button mat-menu-item (click)="openProject('dragontamers')">Dragontamers</button>
                </mat-menu>

                <button mat-flat-button [matMenuTriggerFor]="VideoGames" disabled>Video Games</button>
                <mat-menu #VideoGames="matMenu">
                    <button mat-menu-item (click)="openProject('dragontamers')">Dragontamers</button>
                </mat-menu>

                <button mat-flat-button [matMenuTriggerFor]="TabletopRPGS">Tabletop RPGs</button>
                <mat-menu #TabletopRPGS="matMenu">
                    <button mat-menu-item (click)="openProject('free-maps')">Free Tabletop Maps</button>
                </mat-menu>
            </mat-card-content>
        </mat-card>
    </div>
    <span class="horzspacer">&nbsp;</span>
    <div class="projectsThumbs">
        <div fxFlex="1 1 0" fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="20px">
            <ng-container *ngFor="let project of projectsSource">
                <mat-card>
                    <mat-card-title>{{project.title}}</mat-card-title>
                    <mat-card-subtitle>{{convertDate(project.date, '')}} - {{project.types}}</mat-card-subtitle>
                    <mat-card-content>{{project.shortdesc}}</mat-card-content>
                    <mat-card-actions>
                        <div style="display: flex;">
                            <button [disabled]="project.wip" mat-stroked-button (click)="openProject(project.modalTemplate)">VIEW PROJECT</button>
                            <span>&nbsp;&nbsp;</span>
                            <p *ngIf="project.wip">Work In Progress</p>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </ng-container>
        </div>
    </div>
</div>