import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PasswordMatchValidator } from 'src/app/global-scripts/register-validator';
import { HttpAlertAnimations } from 'src/app/models/HttpAlertAnimations';
import { UserAuthService } from 'src/app/services/user-auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [HttpAlertAnimations]
})
export class RegisterComponent implements OnInit {
  buttonDirection = "highlight_off";
  registerSuccess = null;
  registerFail = null;

  constructor(private UserAuth: UserAuthService, private router: Router) { }

  ngOnInit(): void {
  }

  registerForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    passwordConfirm: new FormControl('', [Validators.required])
  }, 
  { validators: [PasswordMatchValidator.passwordLength,
    PasswordMatchValidator.passwordCase,
    PasswordMatchValidator.passwordNumber,
    PasswordMatchValidator.passwordSpecial,
    PasswordMatchValidator.passwordMismatch
  ]});

  buttonFlip() {
    if(this.buttonDirection === "highlight_off") {
      this.buttonDirection = "arrow_circle_down";
    } else { this.buttonDirection = "highlight_off"; }
  }

  fieldValidationSwitch(variable: string) {
    switch (variable) {
      case "passwordValid":
        if(this.registerForm.hasError("passwordSpecial") ||
            this.registerForm.hasError("passwordCase") ||
            this.registerForm.hasError("passwordNumber") ||
            this.registerForm.hasError("passwordLength")) {
              return "check_box_outline_blank";
            } else {
              return "check_box";
            }
      case "confirmValid":
        if(this.registerForm.hasError("passwordMismatch")) {
          return "check_box_outline_blank";
        } else {
          return "check_box";
        }
    }
    return "check_box_outline_blank";
  }

  register() {
    this.UserAuth.user_Register(this.registerForm).subscribe(
      result => {
        this.registerSuccess = result.displayText;
        setTimeout(() => {
          this.router.navigateByUrl("Login");
        }, 3000);
      },
      error => {
        this.registerFail = error.error.displayText;
        setTimeout(() => {
          this.registerFail = null;
        }, 5000);
      }
    );
  }

}
