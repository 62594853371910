import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AutoLogoutService } from '../services/auto-logout.service';
import { UserAuthService } from '../services/user-auth.service';
import { HttpAlertAnimations } from '../models/HttpAlertAnimations';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [HttpAlertAnimations]
})
export class ContactComponent implements OnInit {
  sentSuccess: string = null;
  sentFail: string = null;
  
  constructor(private Auto: AutoLogoutService, public UserAuth: UserAuthService, public router: Router) { }

  contactForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    company: new FormControl(''),
    subject: new FormControl('Other'),
    message: new FormControl('', [Validators.required])
  });

  ngOnInit(): void {
  }

  sendTicket() {
    this.UserAuth.contactForm(this.contactForm).subscribe(
      result => {
        this.sentSuccess = result.displayText;
        setTimeout(() => {
          this.sentSuccess = null;
          this.router.navigateByUrl('');
        }, 3000);
      },
      error => {
        this.sentFail = error.error.displayText;
        setTimeout(() => {
          this.sentFail = null;
        }, 5000);
      }
    );
  }

}
