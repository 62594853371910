import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PasswordMatchValidator } from 'src/app/global-scripts/register-validator';
import { HttpAlertAnimations } from 'src/app/models/HttpAlertAnimations';
import { UserAuthService } from 'src/app/services/user-auth.service';

@Component({
  selector: 'app-resetpass',
  templateUrl: './resetpass.component.html',
  styleUrls: ['./resetpass.component.scss'],
  animations: [HttpAlertAnimations]
})
export class ResetpassComponent implements OnInit {
  resetToken: string = "";
  successText: string = null;
  failText: string = null;

  constructor(public UserAuth: UserAuthService, public router: Router) { 
    this.resetToken = decodeURIComponent(router.url.split('/').slice(2, router.url.split('/').length).join('/')) + "==";
  }
  
  resetForm = new FormGroup({
    token: new FormControl(this.resetToken),
    password: new FormControl('', [Validators.required]),
    passwordConfirm: new FormControl('', [Validators.required])
  },
  { validators: [PasswordMatchValidator.passwordLength,
    PasswordMatchValidator.passwordCase,
    PasswordMatchValidator.passwordNumber,
    PasswordMatchValidator.passwordSpecial,
    PasswordMatchValidator.passwordMismatch
  ]});

  ngOnInit(): void {
    this.resetForm.controls["token"].setValue(this.resetToken);
  }

  resetPassword() {
    this.UserAuth.password_Reset(this.resetForm).subscribe(
      result => {
        this.successText = result.displayText;
        setTimeout(() => {
          this.successText = null;
          this.router.navigateByUrl('Login');
        }, 3000);
      },
      error => {
        this.failText = error.error.displayText;
        setTimeout(() => {
          this.failText = null;
          this.router.navigateByUrl('Login');
        }, 5000);
      }
    );
  }

}
