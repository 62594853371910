import { Component, OnInit } from '@angular/core';
import { AutoLogoutService } from '../services/auto-logout.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(private Auto: AutoLogoutService) { }

  ngOnInit(): void {
  }

}
