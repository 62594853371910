<div *ngIf="modalData.template === 'josh'" class="dialog-container">
    <div>
        <img src="../assets/josh_profile.jpg">
        <h2>Joshua Cleveland</h2>
        <h3>Principal Agent</h3>
        <h4 class="locale"><i>Central Maryland</i></h4>
    </div>
    <mat-dialog-content>
        <div class="dialog-profile-container">
            <h4><b>Qualifications</b></h4>
            <p>A lifetime board-and-video gamer, I have spent the majority of my free time throughout my youth and adult life immersed in the world of hobby gaming. 
                I try to experience as many different game styles as possible, but I have a particular interest in wargaming and role playing games. 
                Alongside a love of history and fiction writing, I participate in and run multiple tabletop role-playing games in various rules systems, moderate 
                social media gaming servers, as well as write fiction both for tabletop role-playing game supplementation and for my own enjoyment. I possess a Juris 
                Doctorate from the University of Baltimore, and a Bachelor of Arts in Telecommunications from Indiana University. 
            </p>
            <h4><b>Currently Reading</b></h4>
            <ul>
                <li><i>Meditations</i> by Marcus Aurelius</li>
                <li><i>Maxims</i> by Francois de La Rochefoucauld</li>
            </ul>
            <br/>
            <h4><b>Currently Playing</b></h4>
            <ul>
                <li>Homebrew D&D 5E Campaign</li>
                <li>Game Mastering a homebrew D&D 5E Campaign</li>
                <li>Star Wars SAGA Edition Homebrew Campaign</li>
                <li>Magic the Gathering EDH</li>
                <li>Skyrim: SE and Fallout 4 mod lists through Wabbajack</li>
                <li>Jedi: Fallen Order</li>
            </ul>
        </div>
    </mat-dialog-content>
</div>
<div *ngIf="modalData.template === 'nic'" class="dialog-container">
    <div>
        <img src="../assets/nic_profile.png">
        <h2>Nic Rohr</h2>
        <h3>Chief Technical Officer</h3>
        <h4 class="locale"><i>Northern Virginia</i></h4>
    </div>
    <mat-dialog-content>
        <div class="dialog-profile-container">
            <h4><b>Qualifications</b></h4>
            <p>&emsp;I grew up playing video games.  The first games, and some of the first full memories I have, are playing sim city, doom, and roller coaster tycoon with 
                my dad.  I quickly picked up, and continue to love, rpgs.  The first game I ever purchased on my own was FF7.</p>
            <p>&emsp;I enjoy reading a variety of materials from scientific to historical nonfiction, but always have a fantasy novel halfway read.  I hold bachelors’ degrees in 
                Biochemistry and Computer Engineering from Virginia Tech.  I am taking more school to learn about machine learning and AI systems because I like school and I 
                kinda hate myself…</p>
            <p>&emsp;I frequently play in too many table top groups to do anything else with my time.  I do work full time on the side.  Computer stuff.</p> 
            <h4><b>Currently Reading</b></h4>
            <ul>
                <li><i>A People’s History of the United States</i> (Howard Zinn)</li>
                <li><i>The Color of Magic</i> (Terry Pratchett)</li>
                <li><i>Artificial Intelligence: A Modern Approach</i> (Stuart J. Russell and Peter Norvig)</li>
            </ul>
            <br/>
            <h4><b>Currently Playing</b></h4>
            <ul>
                <li><i>Dragon Quest XI</i></li>
                <li><i>Wind Waker</i></li>
                <li><i>Sea of Thieves</i></li>
                <li><i>Halo MCC</i></li>
            </ul>
        </div>
    </mat-dialog-content>
</div>
<div *ngIf="modalData.template === 'joe'" class="dialog-container">
    <div>
        <img src="../assets/joe_profile.jpg">
        <h2>Joseph Grindstaff</h2>
        <h3>Principal Engineer</h3>
        <h4 class="locale"><i>Central Indiana</i></h4>
    </div>
    <mat-dialog-content>
        <div class="dialog-profile-container">
            <h4><b>Qualifications</b></h4>
            <p>Growing up in a remote part of sourthern Indiana on a cattle farm I naturally gravitated towards
                the natural sciences. Throughout my youth and up to today, however, I've harbored a facination with technology
                as well as interactive stories and games. As a boy I would be eager to get the latest console and games (often 2 years late).
                Once I had usable internet in college and was exposed to the wider world of gaming, fantasy novels, 3D modeling, programming,
                web design, etc I veered into these areas for a career in development. I graduated with a B.S. in Computer Science & Informatics,
                and aspire to model and develop with my friends to bring stories and systems to life.
            </p>
            <h4><b>My Reading Queue</b></h4>
            <ul>
                <li><i>Spellmonger</i>; Spellmonger, Book 1</li>
                <li><i>The Oversight</i>; The Oversight Trilogy, Book 1</li>
                <li><i>The Shadow of What Was Lost</i>; The Licanius Trilogy, Book 1</li>
            </ul>
            <br/>
            <h4><b>Currently Playing</b></h4>
            <ul>
                <li><i>Pathfinder: Kingmaker</i></li>
                <li><i>Sea of Thieves</i></li>
                <li><i>Divinity: Original Sin 2</i></li>
            </ul>
        </div>
    </mat-dialog-content>
</div>
<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>CLOSE</button>
</mat-dialog-actions>