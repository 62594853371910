<mat-card>
    <mat-card-title>Contact Form</mat-card-title>
    <mat-card-subtitle>If you have any questions, concerns, or business enquiries please fill out a contact form 
        and let us know!
    </mat-card-subtitle>
    <mat-card-content>
        <form [formGroup]="contactForm">
            <mat-form-field>
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Email Address</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Company / Organization</mat-label>
                <input matInput formControlName="company">
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Nature of Query</mat-label>
                <mat-select formControlName="subject">
                    <mat-option value="Existing & New Product Suggestions">Existing & New Product Suggestions</mat-option>
                    <mat-option value="Sales & Distribution">Sales & Distribution</mat-option>
                    <mat-option value="Bug Reports & Playtesting">Bug Reports & Playtesting</mat-option>
                    <mat-option value="Website & Store Issues">Website & Store Issues</mat-option>
                    <mat-option value="Other">Other</mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Message</mat-label>
                <textarea matInput rows='5' formControlName="message"></textarea>
            </mat-form-field>
        </form>
        <div *ngIf="sentSuccess" [@inOutAnimation] class="http-Success">{{sentSuccess}}</div>
        <div *ngIf="sentFail" [@inOutAnimation] class="http-Fail">{{sentFail}}</div>
    </mat-card-content>
    <mat-card-actions>
        <button [disabled]="!contactForm.valid" mat-stroked-button (click)="sendTicket()">SEND</button>
    </mat-card-actions>
</mat-card>