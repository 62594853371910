<mat-card>
    <mat-card-content>
        <h1>News</h1>

        <h4><b>Prototyping <i>Guns & Glory: Seas of Fame</i></b></h4>
        <h5>Posted: <i>July 23rd, 2021</i></h5>
        <br>
        <p>We have completed our initial playtests of <i>Guns & Glory: Seas of Fame</i> and are heading towards prototyping our first ever board game! 
            We have some cool plans we're nurturing for the first run and are excited to share what we've made. As soon as we have all the pretty things to show, 
            we'll be posting them here for all to see!
        </p>
        <br>
        <h4><b>Geekwerks LLC Founding Meeting!</b></h4>
        <h5>Posted: <i>October 18th, 2020</i></h5>
        <br>
        <p>The founding memebers met in Annapolis to both sign the operating agreement as well as to take in 
            the last sun and suds before the winter and Christmas holiday season arrives!
        </p>
    </mat-card-content>
</mat-card>