export class Project {
    title: string;
    date: Date;
    thumbnail: string;
    shortdesc: string;
    modalTemplate: string;
    types: Array<string>;
    wip: boolean;

    constructor(title, date, types, shortdesc, modalTemplate, thumbnail?, wip?) {
        this.title = title;
        this.date = date;
        this.types = types;
        this.shortdesc = shortdesc;
        this.modalTemplate = modalTemplate;
        this.thumbnail = thumbnail;
        this.wip = wip;
    }
}