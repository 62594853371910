import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserAuthService } from './user-auth.service';

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public UserAuth: UserAuthService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot) : boolean {
    const expectedRole = route.data.expectedRole;
    if(this.UserAuth.isLoggedIn() && this.UserAuth.verifyRole(expectedRole)) {
      return true;
    } else {
      this.router.navigate(['/Login']);
      return false;
    }
  }
}
