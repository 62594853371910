import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProfilesComponent } from '../modals/profiles/profiles.component';
import { AutoLogoutService } from '../services/auto-logout.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(public matDialog: MatDialog, private Auto: AutoLogoutService) { }

  ngOnInit(): void {
  }

  openProfile(templateName: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "profile-modal";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "panel";
    dialogConfig.data = {
      template: templateName
    };
    const modalDialog = this.matDialog.open(ProfilesComponent, dialogConfig);
  }

}
