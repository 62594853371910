<form [formGroup]="postForm">
    <mat-form-field>
        <mat-label>Quoted Text</mat-label>
        <textarea [rows]="5" matInput formControlName="quote"></textarea>
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-label>Post Text</mat-label>
        <textarea [rows]="5" matInput formControlName="text"></textarea>
    </mat-form-field>
</form>
<div style="display: flex;">
    <button mat-stroked-button (click)="post()">POST</button>
    <span style="margin-left: 5px; margin-right: 5px;">&nbsp;</span>
    <button mat-stroked-button (click)="cancel()">CANCEL</button>
</div>