<mat-card>
    <mat-card-title>{{UserAuth.userProfile('fullname')}}</mat-card-title>
    <mat-card-subtitle>{{UserAuth.userProfile('displayName')}}</mat-card-subtitle>
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="Personal Details">
                <ng-template matTabContent>
                    <form [formGroup]="profileForm">
                        <div *ngFor="let field of profileDetail">
                            <div *ngIf="field.type == 'text' || field.type == 'email'">
                                <mat-form-field>
                                    <mat-label>{{field.displayName}}</mat-label>
                                    <input [type]="field.type" [formControlName]="field.name" matInput>
                                </mat-form-field>
                            </div>
                            <div *ngIf="field.type == 'textarea'">
                                <mat-form-field>
                                    <mat-label>{{field.displayName}}</mat-label>
                                    <textarea [formControlName]="field.name" [rows]="7" matInput></textarea>
                                </mat-form-field>
                            </div>
                            <div class="editable" *ngIf="field.editable">
                                <button mat-stroked-button [disabled]="!check_Disabled(field.name)" (click)="switch_State(field.name)">EDIT</button>
                                <button mat-stroked-button [disabled]="check_Disabled(field.name)" (click)="switch_State(field.name)">CANCEL</button>
                            </div>
                        </div>
                        <div><button [disabled]='!profileForm.valid' mat-stroked-button (click)="updatePersonalDetails()">SAVE CHANGES</button></div>
                    </form>
                    <div *ngIf="profileUpdateStatus" [@inOutAnimation] [class]="profileUpdateClass">{{profileUpdateStatus}}</div>
                </ng-template>
            </mat-tab>
            <mat-tab label="Forum Activity">
                <ng-template matTabContent>
                    <mat-tab-group>
                        <mat-tab label="My Posts">
                            <ng-template matTabContent>
                                <div class="activity">
                                    <ng-container *ngFor="let post of postsMade">
                                        <mat-card>
                                            <mat-card-content>
                                                <div class="forum-header">
                                                    Posted On: {{convert_Date(post.submittedDate)}}<br>
                                                    Posted By: {{post.submittedUsername}}<br>
                                                </div>
                                                <div class="quote" *ngIf="post.quotedText">
                                                    <p>{{post.quotedText}}</p>
                                                </div>
                                                <div class="text">
                                                    <p>{{post.text}}</p>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Liked Posts">
                            <ng-template matTabContent>
                                <div class="activity">
                                    <ng-container *ngFor="let post of postsLiked">
                                        <mat-card>
                                            <mat-card-content>
                                                <div class="forum-header">
                                                    Posted On: {{convert_Date(post.submittedDate)}}<br>
                                                    Posted By: {{post.submittedUsername}}<br>
                                                </div>
                                                <div class="quote" *ngIf="post.quotedText">
                                                    <p>{{post.quotedText}}</p>
                                                </div>
                                                <div class="text">
                                                    <p>{{post.text}}</p>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Disliked Posts">
                            <ng-template matTabContent>
                                <div class="activity">
                                    <ng-container *ngFor="let post of postsDisliked">
                                        <mat-card>
                                            <mat-card-content>
                                                <div class="forum-header">
                                                    Posted On: {{convert_Date(post.submittedDate)}}<br>
                                                    Posted By: {{post.submittedUsername}}<br>
                                                </div>
                                                <div class="quote" *ngIf="post.quotedText">
                                                    <p>{{post.quotedText}}</p>
                                                </div>
                                                <div class="text">
                                                    <p>{{post.text}}</p>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Received Replies">
                            <ng-template matTabContent>
                                <div class="activity">
                                    <ng-container *ngFor="let post of repliesReceived">
                                        <mat-card>
                                            <mat-card-content>
                                                <div class="forum-header">
                                                    Posted On: {{convert_Date(post.submittedDate)}}<br>
                                                    Posted By: {{post.submittedUsername}}<br>
                                                </div>
                                                <div class="quote" *ngIf="post.quotedText">
                                                    <p>{{post.quotedText}}</p>
                                                </div>
                                                <div class="text">
                                                    <p>{{post.text}}</p>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>