<div class="user-admin">
    <div class="card-sim">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input [disabled]="!registeredUsersSource.data" matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
        </mat-form-field>
    </div>
    <mat-divider [vertical]="false"></mat-divider>
    <table mat-table [dataSource]="registeredUsersSource" class="mat-elevation-z8">
        <ng-container matColumnDef="date_registered">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Registered</th>
            <td mat-cell *matCellDef="let user">{{user.date_registered | date : "MMM dd, yyyy @ h:mm:ss a"}}</td>
        </ng-container>
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
            <td mat-cell *matCellDef="let user">{{user.firstName}}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
            <td mat-cell *matCellDef="let user">{{user.lastName}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
            <td mat-cell *matCellDef="let user">{{user.userName}}</td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
            <td mat-cell *matCellDef="let user">{{user.email}}</td>
        </ng-container>
        <ng-container matColumnDef="roleNames">
            <th mat-header-cell *matHeaderCellDef>Roles Assigned</th>
            <td mat-cell *matCellDef="let user">{{user.roleNames}}</td>
        </ng-container>
        <ng-container matColumnDef="userStatus">
            <th mat-header-cell *matHeaderCellDef>User Status</th>
            <td mat-cell *matCellDef="let user">{{user.userStatus}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let user"><button mat-stroked-button (click)="selectUser(user)">SELECT</button></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableDisplayCols"></tr>
        <tr mat-row *matRowDef="let user; columns: tableDisplayCols;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">{{searchFailTextLeader}} {{'"' + input.value + '"'}}</td>
        </tr>
    </table>
    <mat-divider [vertical]="false"></mat-divider>
    <mat-card *ngIf="selectedUser">
        <mat-card-title>Selected User</mat-card-title>
        <mat-card-content>
            <div style="width: auto;">
                <ng-container *ngFor="let field of selectedUserFields">
                    <mat-form-field>
                        <mat-label>{{field.displayName}}</mat-label>
                        <input [disabled]="true" [value]="selectedUserInfo(field.name)" matInput>
                    </mat-form-field>
                    <span style="margin-left: 5px; margin-right: 5px;">&nbsp;</span>
                </ng-container>
            </div>
            <form [formGroup]="selectedUserForm">
                <div style="width: auto;">
                    <mat-form-field>
                        <mat-label>Assign Roles</mat-label>
                        <mat-select multiple="true" formControlName="roleNames">
                            <ng-container *ngFor="let option of assignableRoles">
                                <mat-option [value]="option">{{option}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <span style="margin-left: 5px; margin-right: 5px;">&nbsp;</span>
                    <mat-form-field>
                        <mat-label>User Status</mat-label>
                        <mat-select formControlName="userStatus">
                            <ng-container *ngFor="let option of statuses">
                                <mat-option [value]="option">{{option}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <div style="display: flex;">
                <mat-checkbox #areyousure>Delete User?</mat-checkbox>
                <span style="margin-left: 5px; margin-right: 5px;">&nbsp;</span>
                <button [disabled]="!areyousure.checked" mat-stroked-button (click)="deleteUser()">DELETE USER ACCOUNT</button>
            </div>
            <div *ngIf="updateSucceeded" [@inOutAnimation] class="http-Success">{{updateSucceeded}}</div>
            <div *ngIf="updateFailed" [@inOutAnimation] class="http-Fail">{{updateSucceeded}}</div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-stroked-button (click)="updateSelected()">SAVE CHANGES</button>
        </mat-card-actions>
    </mat-card>
</div>