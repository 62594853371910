import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ProjectsComponent } from './projects/projects.component';
import { RegisterComponent } from './auth/register/register.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotpassComponent } from './auth/forgotpass/forgotpass.component';
import { ResetpassComponent } from './auth/resetpass/resetpass.component';
import { ForumComponent } from './forum/forum.component';
import { UserAdminComponent } from './user-admin/user-admin.component';

import { ProjectComponent } from './modals/projects/project.component';
import { ProfilesComponent } from './modals/profiles/profiles.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ForumpostComponent } from './modals/forumpost/forumpost.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { RoleGuardService as RoleGuard } from './services/role-guard.service';


@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    AboutComponent,
    ContactComponent,
    ProjectsComponent,
    RegisterComponent,
    LoginComponent,
    ForgotpassComponent,
    ResetpassComponent,
    ProjectComponent, ProfilesComponent, 
    ForumComponent, 
    UserAdminComponent, MyProfileComponent, ForumpostComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDividerModule,
    FormsModule, ReactiveFormsModule, MatFormFieldModule,
    MatTabsModule,
    MatTableModule, MatPaginatorModule,
    MatSelectModule,
    MatDialogModule,
    MatMenuModule,
    MatCheckboxModule,
    FlexLayoutModule
  ],
  entryComponents: [
    ProfilesComponent,
    ProjectComponent
  ],
  providers: [
    { provide: AuthGuard },
    { provide: RoleGuard },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline'} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
