import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { UserAuthService } from './user-auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  APIURL = environment.postURL;

  constructor(private UserAuth: UserAuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authedUser = this.UserAuth.userProfileSubject.value;
    const isLoggedIn = authedUser && authedUser.usertoken && (!authedUser.sessionExpires || this.UserAuth.isLoggedIn());
    const isValidAPI = req.url.startsWith(this.APIURL);
    if(isLoggedIn && isValidAPI) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + authedUser.usertoken)
      });
      var updatedUser: User = JSON.parse(localStorage.getItem('userProfile'));
      if(dayjs(updatedUser.sessionExpires).diff(dayjs(), 'minutes') <= 5) {
        updatedUser.sessionExpires = dayjs(updatedUser.sessionExpires).add(5, 'minutes').toDate();
        this.UserAuth.userProfile_Update(updatedUser);
      }
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
