import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit {
  templateToUse: string = null;
  
  constructor(public dialogRef: MatDialogRef<ProfilesComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any) { 
      this.templateToUse = modalData.template;
    }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(null);
  }

}
