import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Project } from 'src/app/models/project-object';
import { ProjectDirectory } from 'src/app/models/project-directory';
import { ProjectComponent } from '../modals/projects/project.component';
import { AutoLogoutService } from '../services/auto-logout.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  constructor(public matDialog: MatDialog, private Auto: AutoLogoutService) { }
  
  // projectDirectory : ProjectDirectory[] = [
  //   new ProjectDirectory("Board Games", ["ship-to-ship", "shadow-governance"]),
  //   new ProjectDirectory("Card Games", ["dragontamers"]),
  //   new ProjectDirectory("Video Game", ["dragontamers"])
  // ];

  projectsSource : Project[] = [
    new Project("Guns & Glory: Seas of Fame", new Date(2020,10,1) , ["Board Games"], 
    "A gritty full-table, multiplayer, immersive classical ship combat system.",
    "ship-to-ship", '', true),
    new Project("Shadow Governance", new Date(2021,0,30), ["Board Games"],
    "An area control and resource management board game with table-talk and backstabbing required for victory!",
    "shadow-governance", '', true),
    new Project("Dragontamers", new Date(2021,1,2), ["Card Games", "Video Games"],
    "A collectible card game, yes another one, combining inspiration from many popular such games for the ultimate experience.",
    "dragontamers", '', true),
    new Project("Free Tabletop Maps", new Date(2021,1,19), ["Tabletop RPGs"],
    "Here at Geekwerks, it’s our pleasure to make free maps for use in tabletop games. We want to thank you for visiting our site. Check out other games while you’re here. Come back regularly for more content! We simply ask that you credit us for any usage.",
    "free-maps", '')
  ];

  ngOnInit(): void {
    this.projectsSource.sort((dateA, dateB) => dateA.date.valueOf() - dateB.date.valueOf()).reverse();
  }

  convertDate(date: Date, format: string) : string {
    switch (format) {
      case "date-time": return date.toLocaleDateString + ' @ ' + date.toLocaleTimeString();
      default: return date.toLocaleDateString();
    }
  }

  openProject(templateName: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "project-modal";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "panel";
    dialogConfig.data = {
      template: templateName
    };
    const modalDialog = this.matDialog.open(ProjectComponent, dialogConfig);
  }

}
