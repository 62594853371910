import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-forumpost',
  templateUrl: './forumpost.component.html',
  styleUrls: ['./forumpost.component.scss']
})
export class ForumpostComponent implements OnInit {

  postForm = new FormGroup({
    quotedUser: new FormControl(''),
    quote: new FormControl({value: '', disabled: true}),
    text: new FormControl('', [Validators.required])
  });

  constructor(public dialogRef: MatDialogRef<ForumpostComponent>, 
    @Inject(MAT_DIALOG_DATA) public modalData: any) {
      this.postForm.get("quotedUser").patchValue(modalData.quotedUser);
      this.postForm.get("quote").patchValue(modalData.quote);
      this.postForm.get("text").patchValue(modalData.text);
    }

  ngOnInit(): void {
  }

  post() {
    this.dialogRef.close(this.postForm.getRawValue());
  }

  cancel() {
    this.dialogRef.close();
  }

}
