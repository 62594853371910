<mat-card>
    <mat-card-content>
        <mat-card-title>Sign In</mat-card-title>
        <form [formGroup]="loginForm">
            <mat-form-field>
                <mat-label>Account Email</mat-label>
                <input matInput type="email" required formControlName="email">
                <mat-error>This field is required.</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput type="password" required formControlName="password">
                <mat-error>This field is required.</mat-error>
            </mat-form-field>
        </form>
        <div *ngIf="loginSuccess" [@inOutAnimation] class="http-Success">{{loginSuccess}}</div>
        <div *ngIf="loginFail" [@inOutAnimation] class="http-Fail">{{loginFail}}</div>
    </mat-card-content>
    <mat-card-actions>
        <button mat-stroked-button [disabled]="!loginForm.valid" (click)="login()">SIGN IN</button>
        <button mat-button (click)="this.router.navigateByUrl('ForgotPassword')">Forgot Password</button>
    </mat-card-actions>
</mat-card>