import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { userIndex } from '../global-scripts/userindex';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  userProfileSubject: BehaviorSubject<User>;
  userProfileObs: Observable<User>;

  APIURL = environment.postURL;
  
  public userProfile(output): string {
    if(!this.userProfileSubject.value) { return ""; }
    switch (output) {
      case "fullname": return this.userProfileSubject.value.firstName + " " + this.userProfileSubject.value.lastName;
      case "displayName":
        if(this.userProfileSubject.value.userName_Index) 
        { return this.userProfileSubject.value.userName + userIndex(this.userProfileSubject.value.userName_Index); }
        else { return this.userProfileSubject.value.userName; }
      default: return this.userProfileSubject.value[output];
    }
  }

  get loggedIn() {
    return this.userProfileSubject.value;
  }

  constructor(public http: HttpClient, public router: Router) {
    this.userProfileSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem("userProfile")));
    this.userProfileObs = this.userProfileSubject.asObservable();
  }

  userProfile_Update(updatedUser) {
    this.userProfileSubject.next(updatedUser);
    this.userProfileObs = this.userProfileSubject.asObservable();
    localStorage.setItem('userProfile', JSON.stringify(updatedUser));
  }

  user_Register(formData: FormGroup) {
    return this.http.post<any>(this.APIURL + "/API/RegisterUser", formData.value);
  }

  user_Login(formData: FormGroup) {
    return this.http.post<any>(this.APIURL + "/API/UserLogin", formData.value)
      .pipe(
        map(userData => {
          localStorage.setItem('userProfile', JSON.stringify(userData.userProfile));
          this.userProfileSubject.next(userData.userProfile);
          return userData;
        })
      );
  }

  user_Logout() {
    localStorage.removeItem('userProfile');
    this.userProfileSubject.next(null);
    this.router.navigate(['/Login']);
  }

  isLoggedIn() {
    return this.userProfile("sessionExpires") ? dayjs().isBefore(dayjs(this.userProfile("sessionExpires"))) : false;
  }

  //#region PERMISSONS
  verifyRole(requiredRole: string) {
    return this.userProfile("roleNames").includes(requiredRole);
  }
  //#endregion

  //#region ADMIN USER FUNCTIONS
  admin_GetAllAccounts() {
    return this.http.post<any>(this.APIURL + "/API/AdminGetAllAccounts", {});
  }

  admin_UpdateUser(formData: FormGroup) {
    return this.http.post<any>(this.APIURL + "/API/AdminUserUpdate", formData.value);
  }

  admin_DeleteAccount(id: string) {
    return this.http.delete<any>(this.APIURL + "/API/AdminDeleteUser", {headers: {id: id}});
  }
  //#endregion

  //#region USER PROFILE UPDATE FUNCTIONS
  myProfileUpdate(formData: FormGroup) {
    return this.http.post<any>(this.APIURL + "/API/UserProfileUpdate", formData.value, {headers: {userID: this.userProfile("_id")}});
  }
  //#endregion

  //#region RECOVERY FUNCTIONS
  password_RequestReset(formData: FormGroup) {
    return this.http.post<any>(this.APIURL + "/API/PassResetRequest", formData.value);
  }

  password_Reset(formData: FormGroup) {
    return this.http.post<any>(this.APIURL + "/API/PasswordReset", formData.value);
  }
  //#endregion

  //#region CONTACT FORM
  contactForm(formData: FormGroup) {
    return this.http.post<any>(this.APIURL + "/API/SubmitTicket", formData.value);
  }
  //
}
