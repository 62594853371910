import { AbstractControl, ValidationErrors } from '@angular/forms';

export class PasswordMatchValidator {
    static passwordMismatch(control: AbstractControl) : ValidationErrors | null {
        const pass = control.get('password');
        const passC = control.get('passwordConfirm');
        if((pass.value !== passC.value) || !passC.value) { return {passwordMismatch: true}; }
        return null;
    }

    static passwordLength(control: AbstractControl) : ValidationErrors | null {
        const pass = control.get('password');
        if(pass.value.toString().length <= 7) { return {passwordLength: true}; }
        return null;
    }

    static passwordNumber(control: AbstractControl) : ValidationErrors | null {
        const pass = control.get('password');
        if(!pass.value.toString().match("\\d{1}")) { return {passwordNumber: true}; }
        return null;
    }

    static passwordCase(control: AbstractControl) : ValidationErrors | null {
        const pass = control.get('password');
        if(!pass.value.toString().match("(?=.*?[A-Z])(?=.*?[a-z])")) { return {passwordCase: true}; }
        return null;
    }

    static passwordSpecial(control: AbstractControl) : ValidationErrors | null {
        const pass = control.get('password');
        if(!pass.value.toString().match("(?=.*?[#?!@$%^&*-])")) { return {passwordSpecial: true}; }
        return null;
    }
}