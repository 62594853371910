<mat-card>
    <mat-card-title>Forgot Password</mat-card-title>
    <mat-card-content>
        <form [formGroup]="resetForm">
            <mat-form-field>
                <mat-label>Account Email</mat-label>
                <input matInput type="email" required formControlName="email">
                <mat-error>Please provide a valid email address.</mat-error>
            </mat-form-field>
        </form>
        <div *ngIf="requestSuccess" [@inOutAnimation] class="http-Success">{{requestSuccess}}</div>
        <div *ngIf="requestFail" [@inOutAnimation] class="http-Fail">{{requestFail}}</div>
    </mat-card-content>
    <mat-card-actions>
        <button mat-stroked-button [disabled]="!resetForm.valid" (click)="requestReset()">REQUEST RESET</button>
    </mat-card-actions>
</mat-card>