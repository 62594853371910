import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserAuthService } from './user-auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public UserAuth: UserAuthService, public router: Router) { }

  canActivate() : boolean {
    if(!this.UserAuth.isLoggedIn()) {
      console.log("User Is Not Logged In");
      this.router.navigate(['/Login']);
      return false;
    }
    console.log("User Is logged in");
    return true;
  }
}
