import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ForumpostComponent } from '../modals/forumpost/forumpost.component';
import { ForumService } from '../services/forum.service';
import { UserAuthService } from '../services/user-auth.service';
import { convertDate } from '../global-scripts/convert-date';
import { AutoLogoutService } from '../services/auto-logout.service';
import { HttpAlertAnimations } from '../models/HttpAlertAnimations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss'],
  animations: [HttpAlertAnimations]
})
export class ForumComponent implements OnInit {
  selectedPosts = null;
  currentCategory: string = null;
  currentThread: string = null;
  postPassFail = null;

  forumDirectory = [
    {name: "General", threads: ["User Discussion (Moderated)"]},
    {name: "Playtesting", threads: ["Ship-to-Ship Combat System"]},
  ];

  constructor(public UserAuth: UserAuthService, 
    public ForumSvc: ForumService, 
    public matDialog: MatDialog,
    public router: Router) { }

  ngOnInit(): void {
  }

  selectPosts(cat: string, thr: string) {
    this.currentCategory = cat;
    this.currentThread = thr;
    this.ForumSvc.get_PostsBySelection(cat, thr).subscribe(
      result => {
        this.postPassFail = result.displayText;
        this.selectedPosts = result.posts;
        setTimeout(() => {
          this.postPassFail = null;
        }, 3000);
      },
      error => {
        this.postPassFail = error.error.displayText;
        setTimeout(() => {
          this.postPassFail = null;
        }, 5000);
      }
    );
  }

  formatDate(date) {
    return convertDate(date, "post");
  }

  postComment(postData: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "forum-modal";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "postcomment";
    dialogConfig.data = {
      quotedUser: postData ? postData.submittedID : null,
      quote: postData ? postData.text : null,
      text: ""
    };
    const modalDialog = this.matDialog.open(ForumpostComponent, dialogConfig);
    modalDialog.afterClosed().subscribe(
      data => {
        if(data) {
          this.ForumSvc.add_Post(this.currentCategory, this.currentThread, data).subscribe(
            result => {
              this.selectPosts(this.currentCategory, this.currentThread);
              setTimeout(() => {
                this.scrollToPost(result.post._id);
              }, 3000);
            },
            error => {
              this.postPassFail = error.error.displayText;
              setTimeout(() => {
                this.postPassFail = null;
              }, 5000);
            }
          )
        }
      }
    );
  }

  likeOrdislike(direction: string, postData: any) {
    this.ForumSvc.Like_Dislike(direction, postData).subscribe(
      result => {
        this.selectPosts(this.currentCategory, this.currentThread);
        console.log(result);
      },
      error => {
        //nothing
        console.log(error.error);
      }
    );
  }

  DoIHaveThumbs(direction: string, post: any) {
    if(direction === "up") {
      if(post.likedUsers.includes(this.UserAuth.userProfile("_id"))) { return "thumb"; } else { return "nothumb"; }
    } else {
      if(post.dislikedUsers.includes(this.UserAuth.userProfile("_id"))) { return "thumb"; } else { return "nothumb"; }
    }
  }

  scrollToPost(id: any) {
    var element = document.getElementById(id);
    element.scrollIntoView();
  }


  //Management Functions (ADMIN REQUIRED)
  deleteAllPosts() {
    this.ForumSvc.deleteAll().subscribe(
      result => { console.log(result.displayText); },
      error => { console.log(error.error.displayText); }
    );
  }

}