<mat-toolbar>
  <button class="menu" mat-icon-button (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="example-spacer"></span>
  <img class="logo" src="../assets/GW_Logo_Small.png" alt="logo">
  <span class="example-spacer"></span>
  <span *ngIf="UserAuth.loggedIn" class="welcome">Welcome to Geekwerks, {{UserAuth.userProfile('userName')}}</span>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <div style="display: flex;">
      <div *ngIf="!UserAuth.loggedIn">
        <button mat-flat-button routerLink="Login" routerLinkActive="active">
          <mat-icon>login</mat-icon>
          Sign In
        </button>
      </div>
      <div *ngIf="!UserAuth.loggedIn">
        <button mat-flat-button routerLink="Register" routerLinkActive="active">
          <mat-icon>app_registration</mat-icon>
          Register
        </button>
      </div>
      <div *ngIf="UserAuth.loggedIn">
        <button mat-flat-button routerLink="MyAccount" routerLinkActive="active">
          <mat-icon>manage_accounts</mat-icon>
          My Account
        </button>
      </div>
      <div *ngIf="UserAuth.loggedIn">
        <button mat-flat-button (click)="logout()">
          <mat-icon>logout</mat-icon>
          Sign Out
        </button>
      </div>
    </div>
    <div>
      <a routerLink="" routerLinkActive="active"><div>Welcome</div></a>
      <a routerLink="OurStory" routerLinkActive="active"><div>Our Story</div></a>
      <a routerLink="OurProjects" routerLinkActive="active"><div>Our Projects</div></a>
      <div *ngIf="UserAuth.verifyRole('Admin')"><a routerLink="UserAdmin" routerLinkActive="active"><div>User Administration</div></a></div>
      <a routerLink="Forum" routerLinkActive="active"><div>User Forum</div></a>
      <a routerLink="ContactUs" routerLinkActive="active"><div>Contact Us</div></a>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div>
      <router-outlet></router-outlet>
    </div>
    <footer>
      <div>
        <p>Property of Geekwerks LLC 2020. All rights reserved.</p>
        <p>Designed by Joseph Grindstaff</p>
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>