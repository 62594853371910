<mat-card>
    <mat-card-content>
        <mat-card-title>Account Registration</mat-card-title>
        <form [formGroup]="registerForm">
            <mat-form-field>
                <mat-label>First Name</mat-label>
                <input matInput type="text" required formControlName="firstName">
                <mat-error>This field is required.</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input matInput type="text" required formControlName="lastName">
                <mat-error>This field is required.</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Email Address</mat-label>
                <input matInput type="email" required formControlName="email">
                <mat-error>A valid email is required.</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput type="password" required formControlName="password">
                <mat-icon matSuffix>{{fieldValidationSwitch('passwordValid')}}</mat-icon>
                <mat-error></mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Confirm Password</mat-label>
                <input matInput type="password" required formControlName="passwordConfirm">
                <mat-icon matSuffix>{{fieldValidationSwitch('confirmValid')}}</mat-icon>
                <mat-error></mat-error>
            </mat-form-field>
        </form>
        <div>
            <div style="display:flex">
                <button mat-button style="margin-bottom: 10px;" (click)="buttonFlip()">Show Password Requirements <mat-icon>{{buttonDirection}}</mat-icon></button>
            </div>
            <ul *ngIf="buttonDirection === 'arrow_circle_down'">
                <li>Must contain a number</li>
                <li>Must contain an uppercase character</li>
                <li>Must contain a lowercase character</li>
                <li>Must contain a special character (#?!@$%^&*-)</li>
                <li>Must be 8 characters or more</li>
            </ul>
        </div>
        <div *ngIf="registerSuccess" [@inOutAnimation] class="http-Success">{{registerSuccess}}</div>
        <div *ngIf="registerFail" [@inOutAnimation] class="http-Fail">{{registerFail}}</div>
    </mat-card-content>
    <mat-card-actions>
        <button mat-stroked-button [disabled]="!registerForm.valid" (click)="register()">REGISTER</button>
    </mat-card-actions>
</mat-card>