<mat-card>
    <mat-card-title>Forum Selection</mat-card-title>
    <mat-card-subtitle *ngIf="!UserAuth.isLoggedIn()">Select from the public forum boards in the categories below. (To participate in <i>any</i> board you'll need to register with us <span class="embed-link" (click)="this.router.navigateByUrl('/Register')">here!</span>)</mat-card-subtitle>
    <mat-card-subtitle *ngIf="UserAuth.isLoggedIn()">Select from the forum boards available to your account in the categories below.</mat-card-subtitle>
    <mat-card-content>
        <button mat-stroked-button [matMenuTriggerFor]="General">General</button>
        <button *ngIf="UserAuth.verifyRole('Playtester')" mat-stroked-button [matMenuTriggerFor]="Playtesting">Playtesting</button>
        <!--Mat menus for buttons above-->
        <mat-menu #General="matMenu">
            <ng-container *ngFor="let thread of forumDirectory[0].threads">
                <button mat-menu-item (click)="selectPosts('General', thread)">{{thread}}</button>
            </ng-container>
        </mat-menu>
        <mat-menu #Playtesting="matMenu">
            <ng-container *ngFor="let thread of forumDirectory[1].threads">
                <button mat-menu-item (click)="selectPosts('Playtesting', thread)">{{thread}}</button>
            </ng-container>
        </mat-menu>
        <div *ngIf="selectedPosts && postPassFail" [@inOutAnimation] class="http-Success">{{postPassFail}}</div>
        <div *ngIf="!selectedPosts && postPassFail" [@inOutAnimation] class="http-Fail">{{postPassFail}}</div>
    </mat-card-content>
</mat-card>
<mat-divider [vertical]="false"></mat-divider>
<mat-card>
    <mat-card-title *ngIf="currentThread">{{currentCategory}} > {{currentThread}}</mat-card-title>
    <button mat-flat-button [disabled]="!currentThread || !UserAuth.isLoggedIn()" (click)="postComment('')"><mat-icon>post_add</mat-icon> NEW POST</button>
    <button mat-flat-button *ngIf="UserAuth.isLoggedIn() && UserAuth.verifyRole('Admin')" (click)="deleteAllPosts()">DELETE FORUM CONTENTS</button>
</mat-card>
<mat-divider [vertical]="false"></mat-divider>
<ng-container *ngFor="let post of selectedPosts">
    <mat-card [id]="post._id">
        <mat-card-content>
            <div class="forum-header">
                Posted On: {{formatDate(post.submittedDate)}}<br>
                Posted By: {{post.submittedUsername}}<br>
            </div>
            <div class="quote" *ngIf="post.quotedText">
                <p>{{post.quotedText}}</p>
            </div>
            <div class="text">
                <p>{{post.text}}</p>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button [disabled]="!UserAuth.isLoggedIn()" mat-flat-button (click)="postComment()"><mat-icon>post_add</mat-icon> NEW POST</button>
            <button [disabled]="!UserAuth.isLoggedIn()" mat-flat-button (click)="postComment(post)"><mat-icon>reply</mat-icon> REPLY</button>
            <button [class]="DoIHaveThumbs('up',post)" [disabled]="!UserAuth.isLoggedIn() || DoIHaveThumbs('up',post)==='thumb'" mat-flat-button (click)="likeOrdislike('up', post)"><mat-icon>thumb_up</mat-icon><span *ngIf="post.likedCount > 0"> {{post.likedCount}}</span></button>
            <button [class]="DoIHaveThumbs('down',post)" [disabled]="!UserAuth.isLoggedIn() || DoIHaveThumbs('down',post)==='thumb'" mat-flat-button (click)="likeOrdislike('down', post)"><mat-icon>thumb_down</mat-icon><span *ngIf="post.dislikedCount > 0"> {{post.dislikedCount}}</span></button>
        </mat-card-actions>
    </mat-card>
</ng-container>